import classNames from "classnames";
import React, { memo, ReactElement, useCallback } from "react";
import { ObjectParamField, Param } from "../data/methods";
import FormRow from "./formRow";
import ParamInputSwitch from "./paramInputSwitch";
import "./paramsForm.scss";

interface Props {
  className?: string;
  params: Param[];
  values: unknown[];
  setValues(values: unknown[]): void;
}

const ParamsForm = memo(function ParamsForm({
  className,
  params,
  values,
  setValues,
}: Props): ReactElement {
  const handleValueChange = useCallback(
    (value: any, tag: number | string) => {
      if (typeof tag === "number") {
        const newValues = values.slice();
        newValues[tag] = value;
        setValues(newValues);
      } else {
        const parts = tag.split(".");
        const i = +parts[0];
        const name = parts[1];
        const newValues = values.slice();
        newValues[i] = { ...values[i], [name]: value };
        setValues(newValues);
      }
    },
    [values, setValues],
  );

  return (
    <div className={classNames("params-form", className)}>
      {params.map((param, i) => {
        const value = values[i];
        return (
          <ParamsFormRow
            key={i}
            param={param}
            value={value}
            tag={i}
            onChange={handleValueChange}
          />
        );
      })}
    </div>
  );
});
export default ParamsForm;

interface RowProps {
  param: Param | ObjectParamField;
  value: any;
  tag: any;
  onChange(value: any, tag: any): void;
}

function ParamsFormRow({ param, value, tag, onChange }: RowProps) {
  if (param.type === "object") {
    return (
      <div className="params-form-object-group">
        <div className="params-form-row-label">{param.name}</div>
        {param.fields.map(field => (
          <FormRow
            key={field.name}
            name={`${field.name}${field.required ? "*" : ""}`}
            indented={true}
          >
            <ParamInputSwitch
              className="params-form-row-input"
              type={field.type}
              value={value[field.name] || ""}
              tag={`${tag}.${field.name}`}
              onChange={onChange}
            />
          </FormRow>
        ))}
      </div>
    );
  }
  return (
    <FormRow name={param.name}>
      <ParamInputSwitch
        className="params-form-row-input"
        type={param.type}
        value={value}
        tag={tag}
        onChange={onChange}
      />
    </FormRow>
  );
}
