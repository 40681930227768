export interface Method {
  name: string;
  params: Param[];
}

export type Param = { name: string } & (
  | {
      type: SimpleParamType;
    }
  | { type: "object"; fields: ObjectParamField[] });

export type SimpleParamType =
  | "boolean"
  | "integer"
  | "address"
  | "hash"
  | "blockNumber"
  | "data";

export type ParamType = ObjectParam | SimpleParamType;

export interface ObjectParam {
  type: "object";
  fields: ObjectParamField[];
}

export interface ObjectParamField {
  name: string;
  type: SimpleParamType;
  required?: boolean;
}

const address: Param = { name: "Address", type: "address" };
const index: Param = { name: "Index", type: "integer" };
const blockNumber: Param = { name: "Block number", type: "blockNumber" };
const blockHash: Param = { name: "Block hash", type: "hash" };
const transactionHash: Param = { name: "Transaction hash", type: "hash" };
const data: Param = { name: "Data", type: "data" };

export const methods: Method[] = [
  { name: "web3_clientVersion", params: [] },
  { name: "web3_sha3", params: [] },
  { name: "net_version", params: [] },
  { name: "net_listening", params: [] },
  { name: "net_peerCount", params: [] },
  { name: "eth_protocolVersion", params: [] },
  { name: "eth_syncing", params: [] },
  { name: "eth_coinbase", params: [] },
  { name: "eth_mining", params: [] },
  { name: "eth_hashrate", params: [] },
  { name: "eth_gasPrice", params: [] },
  { name: "eth_accounts", params: [] },
  { name: "eth_blockNumber", params: [] },
  { name: "eth_getBalance", params: [address, blockNumber] },
  { name: "eth_getStorageAt", params: [address, index, blockNumber] },
  { name: "eth_getTransactionCount", params: [address, blockNumber] },
  { name: "eth_getBlockTransactionCountByHash", params: [blockHash] },
  { name: "eth_getBlockTransactionCountByNumber", params: [blockNumber] },
  { name: "eth_getUncleCountByBlockHash", params: [blockHash] },
  { name: "eth_getUncleCountByBlockNumber", params: [blockNumber] },
  { name: "eth_getCode", params: [address, blockNumber] },
  { name: "eth_sign", params: [address, data] },
  {
    name: "eth_sendTransaction",
    params: [
      {
        name: "Options",
        type: "object",
        fields: [
          { name: "from", type: "address", required: true },
          { name: "to", type: "address" },
          { name: "gas", type: "integer" },
          { name: "gasPrice", type: "integer" },
          { name: "value", type: "integer" },
          { name: "data", type: "data", required: true },
          { name: "nonce", type: "integer" },
        ],
      },
    ],
  },
  { name: "eth_sendRawTransaction", params: [data] },
  {
    name: "eth_call",
    params: [
      {
        name: "Options",
        type: "object",
        fields: [
          { name: "from", type: "address" },
          { name: "to", type: "address", required: true },
          { name: "gas", type: "integer" },
          { name: "gasPrice", type: "integer" },
          { name: "value", type: "integer" },
          { name: "data", type: "data" },
        ],
      },
      blockNumber,
    ],
  },
  {
    name: "eth_estimateGas",
    params: [
      {
        name: "Options",
        type: "object",
        fields: [
          { name: "from", type: "address" },
          { name: "to", type: "address" },
          { name: "gas", type: "integer" },
          { name: "gasPrice", type: "integer" },
          { name: "value", type: "integer" },
          { name: "data", type: "data" },
        ],
      },
      blockNumber,
    ],
  },
  {
    name: "eth_getBlockByHash",
    params: [
      blockHash,
      { name: "Return full transaction objects", type: "boolean" },
    ],
  },
  {
    name: "eth_getBlockByNumber",
    params: [
      blockNumber,
      { name: "Return full transaction objects", type: "boolean" },
    ],
  },
  { name: "eth_getTransactionByHash", params: [transactionHash] },
  { name: "eth_getTransactionByBlockHashAndIndex", params: [blockHash, index] },
  {
    name: "eth_getTransactionByBlockNumberAndIndex",
    params: [blockNumber, index],
  },
  { name: "eth_getTransactionReceipt", params: [transactionHash] },
  { name: "eth_pendingTransactions", params: [] },
  { name: "eth_getUncleByBlockHashAndIndex", params: [blockHash, index] },
  { name: "eth_getUncleByBlockNumberAndIndex", params: [blockNumber, index] },
  // TODO: Figure out how to handle topics param.
  // {
  //   name: "eth_getLogs",
  //   params: [
  //     {
  //       name: "Options",
  //       type: "object",
  //       fields: [
  //         { name: "fromBlock", type: "blockNumber" },
  //         { name: "toBlock", type: "blockNumber" },
  //         { name: "address", type: "address" },
  //         { name: "topics", type: "topics" },
  //         { name: "blockHash", type: "hash" },
  //       ],
  //     },
  //   ],
  // },
  {
    name: "alchemy_getTokenAllowance",
    params: [
      {
        name: "Options",
        type: "object",
        fields: [
          { name: "contract", type: "address", required: true },
          { name: "owner", type: "address", required: true },
          { name: "spender", type: "address", required: true },
        ],
      },
    ],
  },
  // TODO: figure out what to do about address list.
  // { name: "alchemy_getTokenBalances", params: [address, []] },
  { name: "alchemy_getTokenMetadata", params: [address] },
];
