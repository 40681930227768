export enum EthNetwork {
  MAINNET = "mainnet",
  GOERLI = "goerli",
  KOVAN = "kovan",
  RINKEBY = "rinkeby",
  ROPSTEN = "ropsten",
}

export const ethNetworks = [
  EthNetwork.MAINNET,
  EthNetwork.GOERLI,
  EthNetwork.KOVAN,
  EthNetwork.RINKEBY,
  EthNetwork.ROPSTEN,
];

export const defaultUrlsByNetwork: Record<EthNetwork, string> = {
  [EthNetwork.MAINNET]:
    "https://eth-mainnet.alchemyapi.io/jsonrpc/18lHSAeZttzI4wJvHpXlJGHK4ZDNCTCP",
  [EthNetwork.GOERLI]:
    "https://eth-goerli.alchemyapi.io/jsonrpc/7uIf4QFpJIDY5G0NvGqAQQ9v52V89o6T",
  [EthNetwork.KOVAN]:
    "https://eth-kovan.alchemyapi.io/jsonrpc/nGbclHFbxRN2YfO_0tVO0ZPHCO16HUb2",
  [EthNetwork.RINKEBY]:
    "https://eth-rinkeby.alchemyapi.io/jsonrpc/pD6NE41ES8gXalyax7WtS77DmdQPzCnA",
  [EthNetwork.ROPSTEN]:
    "https://eth-ropsten.alchemyapi.io/jsonrpc/LNhYAtI5bjJambtj5sp_7Zvp9uJTF6AQ",
};
