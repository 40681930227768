import { JsonRpcRequest, JsonRpcResponse } from "./jsonRpc";

export function sendRequest(
  url: string,
  request: JsonRpcRequest,
): Promise<JsonRpcResponse> {
  return fetch(url, {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  }).then(response => response.json());
}
