import React from "react";
import MasterLayout from "../components/masterLayout";
import MethodBuilder from "../components/methodBuilder";
import { methods } from "../data/methods";

const IndexPage = () => (
  <MasterLayout title="Request Builder">
    <MethodBuilder methods={methods} />
  </MasterLayout>
);

export default IndexPage;
