import classNames from "classnames";
import React, { memo, ReactElement, ReactNode } from "react";

interface Props {
  className?: string;
  name: string;
  indented?: boolean;
  children: ReactNode;
}

const FormRow = memo(function FormRow({
  className,
  name,
  indented,
  children,
}: Props): ReactElement {
  return (
    <div className={classNames("row align-items-center my-2", className)}>
      <div className="col-4">
        <div className={classNames(indented && "pl-3")}>{name}</div>
      </div>
      <div className="col-8">{children}</div>
    </div>
  );
});
export default FormRow;
