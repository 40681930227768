import classNames from "classnames";
import React, { ChangeEvent, memo, ReactElement, useCallback } from "react";
import "./checkbox.scss";

interface Props {
  className?: string;
  checked: boolean;
  tag?: any;
  onChange(checked: boolean, tag: any): void;
}

const Checkbox = memo(function Checkbox({
  className,
  checked,
  tag,
  onChange,
}: Props): ReactElement {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      onChange(event.currentTarget.checked, tag),
    [tag, onChange],
  );
  return (
    <input
      type="checkbox"
      className={classNames("checkbox", className)}
      checked={checked}
      onChange={handleChange}
    />
  );
});
export default Checkbox;
