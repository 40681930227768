import React, { memo, ReactElement, useCallback } from "react";
import { EthNetwork, ethNetworks } from "../data/networks";
import Checkbox from "./checkbox";
import FormRow from "./formRow";
import SelectInput from "./selectInput";
import TextInput from "./textInput";
import "./urlInput.scss";

interface UrlInputState {
  useDefaultNetwork: boolean;
  selectedDefaultNetwork: EthNetwork;
  url: string;
}

interface Props extends UrlInputState {
  className?: string;
  useDefaultNetwork: boolean;
  selectedDefaultNetwork: EthNetwork;
  url: string;
  onUseDefaultNetworkChange(useDefaultNetwork: boolean): void;
  onSelectedDefaultNetworkChange(selectedDefaultNetwork: EthNetwork): void;
  onUrlChange(url: string): void;
}

const UrlInput = memo(function UrlInput({
  className,
  useDefaultNetwork,
  selectedDefaultNetwork,
  url,
  onUseDefaultNetworkChange,
  onSelectedDefaultNetworkChange,
  onUrlChange,
}: Props): ReactElement {
  const handleUseDefaultNetworkChange = useCallback(
    (useNetwork: boolean) => onUseDefaultNetworkChange(useNetwork),
    [onUseDefaultNetworkChange],
  );
  const handleSelectedDefaultNetworkChange = useCallback(
    (network: EthNetwork) => onSelectedDefaultNetworkChange(network),
    [onSelectedDefaultNetworkChange],
  );

  return (
    <div className={className}>
      <FormRow name={useDefaultNetwork ? "Network" : "URL"}>
        {useDefaultNetwork ? (
          <SelectInput
            options={networkOptions}
            value={selectedDefaultNetwork}
            onChange={handleSelectedDefaultNetworkChange}
          />
        ) : (
          <TextInput
            value={url}
            placeholder="https://eth-mainnet.alchemyapi.io/…"
            onChange={onUrlChange}
          />
        )}
      </FormRow>
      <FormRow name="">
        <div className="url-input-use-default-row d-flex align-items-center ml-1">
          <Checkbox
            checked={useDefaultNetwork}
            onChange={handleUseDefaultNetworkChange}
          />
          <div className="url-input-use-default-label ml-1 mt-1">
            Use default API token
          </div>
        </div>
      </FormRow>
    </div>
  );
});
export default UrlInput;

const networkOptions = ethNetworks.map(network => ({
  label: capitalize(network),
  value: network,
}));

function capitalize(s: string) {
  return s ? `${s[0].toUpperCase()}${s.slice(1).toLowerCase()}` : s;
}
