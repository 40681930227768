import classNames from "classnames";
import React, {
  ChangeEvent,
  FocusEvent,
  memo,
  ReactElement,
  useCallback,
  useState,
} from "react";
import "./textInput.scss";

interface Props {
  className?: string;
  value: string;
  placeholder: string;
  tag?: any;
  validate?(value: string): string | undefined;
  onChange(value: string, tag: any): void;
}

const TextInput = memo(function TextInput({
  className,
  value,
  placeholder,
  tag,
  validate,
  onChange,
}: Props): ReactElement {
  const [validationError, setValidationError] = useState<string | undefined>(
    undefined,
  );
  const fixedValidate = useCallback(
    (v: string): string | undefined => {
      // Like validate, but also allows empty imput.
      return !validate || !v ? undefined : validate(v);
    },
    [validate],
  );
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (validationError) {
        // Only show validation errors in real time if there's already an
        // error, so that we don't show angry red when the user is filling out
        // the form for the first time.
        setValidationError(fixedValidate(event.currentTarget.value));
      }
      onChange(event.currentTarget.value, tag);
    },
    [tag, onChange, validationError, fixedValidate],
  );
  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) =>
      setValidationError(fixedValidate(event.currentTarget.value)),
    [fixedValidate],
  );

  return (
    <div className={className}>
      <input
        className={classNames(
          "text-input",
          validationError && "text-input-error",
        )}
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {validationError && (
        <div className="text-input-error-text">{validationError}</div>
      )}
    </div>
  );
});
export default TextInput;
