import { chainFrom } from "transducist";
import { Method } from "./methods";
import { validateParam } from "./validation";

export interface JsonRpcRequest {
  jsonrpc: "2.0";
  id?: number | string | null;
  method: string;
  params?: any[];
}

export type JsonRpcResponse = JsonRpcSuccessResponse | JsonRpcErrorResponse;

export interface JsonRpcSuccessResponse {
  jsonrpc: "2.0";
  id: number | string | null;
  result: any;
}

export interface JsonRpcErrorResponse {
  jsonrpc: "2.0";
  id: number | string | null;
  error: {
    code: number;
    message: string;
    data?: any;
  };
}

export function isSuccessResponse(
  response: JsonRpcResponse,
): response is JsonRpcSuccessResponse {
  return (response as JsonRpcSuccessResponse).result !== undefined;
}

export function getRequest(method: Method, values: any[]): JsonRpcRequest {
  const params =
    method.params.length === 0
      ? undefined
      : method.params.map((param, i) => {
          const value = values[i];
          if (param.type === "object") {
            return chainFrom(param.fields)
              .filter(field => !!field.required || !!value[field.name])
              .map(field => field.name)
              .toObject(name => name, name => value[name]);
          } else {
            const validation = validateParam(param.type, value);
            return validation.isValid ? validation.value : value;
          }
        });
  return {
    jsonrpc: "2.0",
    id: 0,
    method: method.name,
    ...(params !== undefined ? { params } : {}),
  };
}
