import classNames from "classnames";
import React, { memo, ReactElement } from "react";
import { JsonRpcRequest, JsonRpcResponse } from "../data/jsonRpc";
import "./fetchView.scss";
import JsonView from "./jsonView";

interface Props {
  className?: string;
  request?: JsonRpcRequest;
  response?: JsonRpcResponse;
}

const FetchView = memo(function FetchView({
  className,
  request,
  response,
}: Props): ReactElement | null {
  if (!request) {
    return null;
  }
  return (
    <div className={classNames("row", className)}>
      {request && (
        <div className="col-12 col-lg-4">
          <h5>Request</h5>
          <JsonView src={request} />
        </div>
      )}
      {response && (
        <div className="col-12 col-lg-8 mt-4 mt-lg-0">
          <h5>Response</h5>
          <JsonView src={response} />
        </div>
      )}
    </div>
  );
});
export default FetchView;
