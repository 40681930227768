import assertNever from "assert-never";
import classNames from "classnames";
import React, {
  memo,
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { chainFrom } from "transducist";
import { sendRequest } from "../data/fetch";
import { getRequest, JsonRpcRequest, JsonRpcResponse } from "../data/jsonRpc";
import { Method, Param, SimpleParamType } from "../data/methods";
import { defaultUrlsByNetwork, EthNetwork } from "../data/networks";
import { areParamsValid } from "../data/validation";
import FetchView from "./fetchView";
import FormRow from "./formRow";
import JsonView from "./jsonView";
import "./methodBuilder.scss";
import ParamsForm from "./paramsForm";
import SelectInput from "./selectInput";
import UrlInput from "./urlInput";

interface Props {
  className?: string;
  methods: Method[];
}

const MethodBuilder = memo(function MethodBuilder({
  className,
  methods,
}: Props): ReactElement {
  const [selectedMethodName, setSelectedMethodName] = useState(
    "eth_getBlockByNumber",
  );
  const methodsByName = useMemo(
    () => chainFrom(methods).toObject(method => method.name, method => method),
    [methods],
  );
  const method = methodsByName[selectedMethodName];
  const [paramValues, setParamValues] = useState(
    getDefaultParamsForMethod(method),
  );
  const [useDefaultNetwork, setUseDefaultNetwork] = useState(true);
  const [selectedDefaultNetwork, setSelectedDefaultNetwork] = useState(
    EthNetwork.MAINNET,
  );
  const [selectedUrl, setSelectedUrl] = useState("");

  const url = useDefaultNetwork
    ? defaultUrlsByNetwork[selectedDefaultNetwork]
    : selectedUrl;

  const { fetchedRequest, fetchedResponse, fetchJsonRpc } = useFetchJsonRpc(
    url,
  );

  const request = useMemo(() => getRequest(method, paramValues), [
    method,
    paramValues,
  ]);
  const options = useMemo(
    () =>
      methods
        .map(m => ({ value: m.name, label: m.name }))
        .sort((m1, m2) =>
          m1.label < m2.label ? -1 : m1.label > m2.label ? 1 : 0,
        ),
    [methods],
  );

  const handleMethodChange = useCallback((methodName: string) => {
    setSelectedMethodName(methodName);
    const newMethod = methodsByName[methodName];
    setParamValues(getDefaultParamsForMethod(newMethod));
  }, []);
  const submitRequest = useCallback(() => fetchJsonRpc(request), [request]);

  return (
    <div className={classNames("method-builder container my-3", className)}>
      <div className="row">
        <div className="col-12 col-lg-8 d-flex flex-column">
          <h5>Configure Request</h5>
          <UrlInput
            useDefaultNetwork={useDefaultNetwork}
            selectedDefaultNetwork={selectedDefaultNetwork}
            url={selectedUrl}
            onUseDefaultNetworkChange={setUseDefaultNetwork}
            onSelectedDefaultNetworkChange={setSelectedDefaultNetwork}
            onUrlChange={setSelectedUrl}
          />
          <FormRow name="Method">
            <SelectInput
              options={options}
              value={selectedMethodName}
              onChange={handleMethodChange}
            />
          </FormRow>
          <ParamsForm
            params={method.params}
            values={paramValues}
            setValues={setParamValues}
          />
          <div className="flex-fill" />
          <button
            className="align-self-start mt-2 btn btn-primary"
            type="button"
            onClick={submitRequest}
            disabled={!areParamsValid(method.params, paramValues)}
          >
            Send Request
          </button>
        </div>
        <div className="col-12 col-lg-4 mt-4 mt-lg-0">
          <h5>Preview</h5>
          <JsonView src={request} />
        </div>
      </div>
      {fetchedRequest && <div className="divider my-4" />}
      <FetchView request={fetchedRequest} response={fetchedResponse} />
    </div>
  );
});
export default MethodBuilder;

function useFetchJsonRpc(url: string) {
  const urlRef = useRef(url);
  urlRef.current = url;
  const fetchedRequestRef = useRef<JsonRpcRequest>();
  const [fetchedRequest, setFetchedRequest] = useState<JsonRpcRequest>();
  const [fetchedResponse, setFetchedResponse] = useState<JsonRpcResponse>();

  const fetchJsonRpc = useRef(async (request: JsonRpcRequest) => {
    fetchedRequestRef.current = request;
    setFetchedRequest(request);
    setFetchedResponse(undefined);
    const response = await sendRequest(urlRef.current, request);
    if (fetchedRequestRef.current === request) {
      setFetchedResponse(response);
    }
  }).current;

  return { fetchedRequest, fetchedResponse, fetchJsonRpc };
}

function getDefaultParamsForMethod(method: Method): unknown[] {
  return method.params.map(getDefaultValueForParam);
}

function getDefaultValueForParam(param: Param): unknown {
  switch (param.type) {
    case "object":
      return chainFrom(param.fields)
        .filter(field => !!field.required)
        .toObject(
          field => field.name,
          field => getDefaultValueForSimpleParamType(field.type),
        );
    default:
      return getDefaultValueForSimpleParamType(param.type);
  }
}

function getDefaultValueForSimpleParamType(type: SimpleParamType): unknown {
  switch (type) {
    case "boolean":
      return false;
    case "integer":
      return "";
    case "address":
      return "";
    case "hash":
      return "";
    case "blockNumber":
      return "latest";
    case "data":
      return "0x0";
    default:
      return assertNever(type);
  }
}
