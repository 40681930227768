import classNames from "classnames";
import React, { memo, ReactElement } from "react";
import { CollapsedFieldProps } from "react-json-view";
import Loadable from "react-loadable";
import "./jsonView.scss";

interface Props {
  className?: string;
  src: object;
}

const JsonView = memo(function JsonView({
  className,
  src,
}: Props): ReactElement {
  return (
    <div className={classNames("json-view p-2", className)}>
      <ReactJson
        collapsed={3}
        shouldCollapse={shouldCollapse}
        displayDataTypes={false}
        displayObjectSize={false}
        name={null}
        src={src}
      />
    </div>
  );
});
export default JsonView;

const ReactJson = Loadable({
  loader: () => import("react-json-view"),
  loading: () => <div className="json-view-placeholder" />,
});

function shouldCollapse({ src }: CollapsedFieldProps): boolean {
  return Array.isArray(src) && src.length > 10;
}
