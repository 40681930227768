import assertNever from "assert-never";
import React, { memo, ReactElement } from "react";
import { SimpleParamType } from "../data/methods";
import { validateParam } from "../data/validation";
import Checkbox from "./checkbox";
import TextInput from "./textInput";

interface Props {
  className?: string;
  type: SimpleParamType;
  value: any;
  tag?: any;
  onChange(value: any, tag: any): void;
}

const ParamInputSwitch = memo(function ParamInputSwitch({
  className,
  type,
  value,
  tag,
  onChange,
}: Props): ReactElement {
  switch (type) {
    case "boolean":
      return (
        <Checkbox
          className={className}
          checked={value}
          tag={tag}
          onChange={onChange}
        />
      );
    case "integer":
      return (
        <TextInput
          className={className}
          value={value}
          placeholder="255 or 0xff"
          tag={tag}
          validate={validateInteger}
          onChange={onChange}
        />
      );
    case "address":
      return (
        <TextInput
          className={className}
          value={value}
          placeholder="0x2f4bdaf…"
          tag={tag}
          validate={validateAddress}
          onChange={onChange}
        />
      );
    case "hash":
      return (
        <TextInput
          className={className}
          value={value}
          placeholder="0xa4dc872…"
          tag={tag}
          validate={validateHash}
          onChange={onChange}
        />
      );
    case "blockNumber":
      return (
        <TextInput
          className={className}
          value={value}
          placeholder={'255, 0xff, "latest"'}
          tag={tag}
          validate={validateBlockNumber}
          onChange={onChange}
        />
      );
    case "data":
      return (
        <TextInput
          className={className}
          value={value}
          placeholder="0x0000000…"
          tag={tag}
          validate={validateData}
          onChange={onChange}
        />
      );
    default:
      return assertNever(type);
  }
});
export default ParamInputSwitch;

function validationFor(paramType: SimpleParamType) {
  return (value: string): string | undefined => {
    const result = validateParam(paramType, value);
    return result.isValid ? undefined : result.error;
  };
}

const validateInteger = validationFor("integer");
const validateAddress = validationFor("address");
const validateHash = validationFor("hash");
const validateBlockNumber = validationFor("blockNumber");
const validateData = validationFor("data");
