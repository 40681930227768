import classNames from "classnames";
import React, { memo, ReactElement, useCallback } from "react";
import Select from "react-select";
// tslint:disable-next-line: no-submodule-imports
import { ValueType } from "react-select/src/types";

export interface SelectInputProps<T> {
  className?: string;
  options: Array<SelectOption<T>>;
  value: T;
  tag?: any;
  onChange(value: T, tag: any): void;
}

export interface SelectOption<T> {
  value: T;
  label: string;
}

const SelectInput = memo(function SelectInput<T>({
  className,
  options,
  value,
  tag,
  onChange,
}: SelectInputProps<T>): ReactElement {
  const handleChange = useCallback(
    (wrappedValue: ValueType<SelectOption<T>>): void => {
      const newValue = unwrapValue(wrappedValue);
      if (newValue != null && value !== newValue) {
        onChange(newValue, tag);
      }
    },
    [value, onChange],
  );

  return (
    <Select<SelectOption<T>>
      className={classNames("select-input", className)}
      options={options}
      classNamePrefix="select-input"
      value={getOptionForValue(value)}
      onChange={handleChange}
    />
  );

  function getOptionForValue(v: T): SelectOption<T> | undefined {
    return options.find(option => option.value === v);
  }

  function unwrapValue(v: ValueType<SelectOption<T>>): T | undefined {
    if (!v) {
      return undefined;
    }
    if (Array.isArray(v)) {
      return v.length > 0 ? v[0].value : undefined;
    }
    return (v as any).value;
  }
});
export default SelectInput;
