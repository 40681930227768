import { ObjectParamField, Param, SimpleParamType } from "./methods";

export type ValidationResult = ValidationSuccess | ValidationFailure;

export interface ValidationSuccess {
  isValid: true;
  value: any;
}

export interface ValidationFailure {
  isValid: false;
  error: string;
}

const HEX_REGEX = /^0x[0-9a-fA-F]+$/;
const ADDRESS_LENGTH = 40;
const HASH_LENGTH = 64;

export function areParamsValid(params: Param[], values: any[]): boolean {
  return params.every((param, i) => {
    const value = values[i];
    if (param.type === "object") {
      return param.fields.every(field =>
        isFieldValid(field, value[field.name]),
      );
    } else {
      return validateParam(param.type, value).isValid;
    }
  });
}

export function validateParam(
  paramType: SimpleParamType,
  value: any,
): ValidationResult {
  switch (paramType) {
    case "boolean":
      return { isValid: true, value };
    case "integer":
      return validateDecimalOrHex(value);
    case "address":
      return validateHex(value, ADDRESS_LENGTH);
    case "hash":
      return validateHex(value, HASH_LENGTH);
    case "blockNumber":
      return validateBlockNumber(value);
    case "data":
      return validateHex(value);
  }
}

function isFieldValid(
  field: ObjectParamField,
  value: string | boolean | undefined,
): boolean {
  if (typeof value === "boolean") {
    return true;
  }
  if (!value) {
    return !field.required;
  }
  return validateParam(field.type, value).isValid;
}

function validateBlockNumber(value: string): ValidationResult {
  const strippedValue = stripQuotes(value);
  if (
    strippedValue === "latest" ||
    strippedValue === "earliest" ||
    strippedValue === "pending"
  ) {
    return { isValid: true, value: strippedValue };
  }
  const integerValidation = validateDecimalOrHex(value);
  return integerValidation.isValid
    ? integerValidation
    : {
        isValid: false,
        error:
          'Must be a decimal or hexidecimal number, or one of "latest", "earliest", or "pending".',
      };
}

function validateDecimalOrHex(
  value: string,
  requiredHexLength?: number,
): ValidationResult {
  if (value.match(HEX_REGEX)) {
    return validateHex(value, requiredHexLength);
  }
  if (value.match(/^\d+$/)) {
    const hexValue = `0x${(+value).toString(16)}`;
    return validateHex(hexValue);
  }
  return { isValid: false, error: "Must be a decimal or hexidecimal number." };
}

function validateHex(value: string, requiredLength?: number): ValidationResult {
  if (!value.match(HEX_REGEX)) {
    return { isValid: false, error: "Must be a hexidecimal number." };
  }
  if (requiredLength != null && value.length !== requiredLength + 2) {
    return {
      isValid: false,
      error: `Must be ${requiredLength} hexidecimal digits.`,
    };
  }
  return { isValid: true, value };
}

function stripQuotes(s: string): string {
  return s[0] === '"' && s[s.length - 1] === '"' ? s.slice(1, s.length - 1) : s;
}
